import React from "react"
import Layout from "../components/Layout"
// import * as styles from "../styles/home.module.css"

export default function NotFound() {
  return (
    <Layout>
      <div
        style={{
          margin: "auto",
          width: "50%",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <h1>404 </h1> <br />
        <h2>Sorry, that page doesn't exist</h2>
      </div>
    </Layout>
  )
}
